import React from 'react';

/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */

import { Play } from '@components/icon/icon';

const PlayIcon = () => {
    return (
        <div className="play-icon">
            <Play />            
        </div>
    )
}

export default PlayIcon;